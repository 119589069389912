.subscription-card {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 400px;
  min-width: 350px;
  margin: auto;
  font-family: 'Segoe UI', sans-serif;
  color: #333;
  text-align: center;
  transition: all 0.3s ease;
}

.subscription-header {
  margin-bottom: 16px;
}

.subscription-icon {
  font-size: 32px;
  color: #6335c9;
}

.subscription-header h2 {
  font-size: 24px;
  margin: 8px 0;
}

.subscription-active {
  font-size: 14px;
  color: #777;
}

.subscription-price {
  margin: 20px 0;
}

.subscription-price .netto {
  font-size: 24px;
  font-weight: bold;
  color: #6335c9;
}

.subscription-price .netto span {
  font-size: 14px;
  color: #555;
  margin-left: 4px;
}

.subscription-price .brutto {
  font-size: 14px;
  color: #999;
}



.next-renewal {
  margin-top:10px;
  font-size: 13px;
  color: #777;
}

.subscription-countdown {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
  color: #444;
}

.subscription-countdown div {
  flex: 1;
}

.subscription-countdown strong {
  display: block;
  font-size: 20px;
  color: #00BFA6;
}

@media (max-width: 480px) {
  .subscription-card {
    padding: 16px;
  }

  .subscription-countdown {
    flex-wrap: wrap;
    gap: 12px;
  }

  .subscription-countdown div {
    width: 48%;
    margin-bottom: 8px;
  }
}

.token-card2 {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  padding: 5px;
  max-width: 300px;
  font-family: 'Segoe UI', sans-serif;
  color: #333;
  text-align: center;
  transition: all 0.3s ease;
}


.token-card2.active {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 15px rgb(1, 104, 145);
  padding: 5px;
  max-width: 300px;
  font-family: 'Segoe UI', sans-serif;
  color: #333;
  text-align: center;
  transition: all 0.3s ease;
}

.token-card {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 400px;
  margin: auto;
  font-family: 'Segoe UI', sans-serif;
  color: #333;
  text-align: center;
  transition: all 0.3s ease;
}

.token-header {
  margin-bottom: 20px;
}

.token-icon {
  font-size: 36px;
  color: #FFA500; /* złoty */
  margin-bottom: 8px;
}

.token-header h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 6px;
}

.token-description {
  font-size: 14px;
  color: #666;
}

.token-button {
  background-color: #f0f0f0 !important;
  border: none !important;
  color: #444 !important;

  border-radius: 8px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-bottom: 20px !important;
  transition: background 0.2s ease !important;
}

.token-button:hover {
  background-color: #e0e0e0 !important;
}

.token-footer {
  background: #f9f9f9;
  border-top: 1px solid #ddd;
  padding: 12px;
  font-size: 14px;
  color: #444;
  font-weight: 500;
  border-radius: 0 0 16px 16px;
}

.token-count {
  font-weight: bold;
  margin-left: 4px;
  color: #2876a4;
}

.token-count.zero {
  color: #d80000;
}

@media (max-width: 480px) {
  .token-card {
    padding: 16px;
  }
  
}


.dashboard-cards {
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}